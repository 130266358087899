import HomeLayout from "src/Vistas/LayOuts/HomeLayout.vue";

import VistasEmpleadoLayout from "src/Vistas/LayOuts/EmpleadoLayout.vue";
let empleadoPages = {
  path: "/layOut/empleado",
  component: VistasEmpleadoLayout,
  name: "Main",
  children: [
    {
      path: "/main/empleado",
      name: "Inicio",
      component: () =>
        import(
          /* webpackChunkName: "empleado" */ "src/Vistas/Autoservicio/Home.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/empleado",
      name: "Autoservicio",
      component: () =>
        import(
          /* webpackChunkName: "empleado" */ "src/Vistas/Autoservicio/Home.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/n/:notificacionID",
      name: "Notificacion",
      component: () =>
        import(
          /* webpackChunkName: "empleado" */ "src/Vistas/Autoservicio/Home.vue"
        ),
      meta: { requiresAuth: true },
    },

    {
      path: "/empleado/acceso-nuevo",
      name: "Nueva Checada",
      component: () =>
        import(
          /* webpackChunkName: "empleado" */ "src/Vistas/Accesos/EmpleadoNuevo.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/empleado/asistencias",
      name: "Asistencia",
      component: () =>
        import(
          /* webpackChunkName: "empleado" */ "src/Vistas/Asistencias/EmpleadoListado.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/empleado/turnos",
      name: "Turnos Empleado",
      component: () =>
        import(
          /* webpackChunkName: "empleado" */ "src/Vistas/Turnos/EmpleadoListado.vue"
        ),
      meta: { requiresAuth: true },
    },

    {
      path: "/empleado/incidencias",
      name: "Permisos o Justificaciones",
      component: () =>
        import(
          /* webpackChunkName: "empleado" */ "src/Vistas/Asistencias/EmpleadoIncidenciasSolicitud.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/empleado/vacaciones",
      name: "Vacaciones",
      component: () =>
        import(
          /* webpackChunkName: "empleado" */ "src/Vistas/Asistencias/EmpleadoVacacionesSolicitud.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/empleado/usuario",
      name: "Yo",
      component: () =>
        import(
          /* webpackChunkName: "empleado" */ "src/Vistas/Usuarios/PerfilPropio.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/empleado/comentarios",
      name: "Comentarios",
      component: () =>
        import(
          /* webpackChunkName: "empleado" */ "src/Vistas/Main/Comentarios.vue"
        ),
      meta: { requiresAuth: true },
    },
  ],
};

let autoservicioPages = {
  path: "/layOut/empleado",
  component: HomeLayout,
  name: "AutoServicioMain",
  children: [
    {
      path: "/autoservicio",
      name: "Autoservicio Home",
      component: () =>
        import(
          /* webpackChunkName: "empleado" */ "src/Vistas/Autoservicio/Home.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/autoservicio/acceso-nuevo",
      name: "Nueva Checada",
      component: () =>
        import(
          /* webpackChunkName: "empleado" */ "src/Vistas/Accesos/EmpleadoNuevo.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/autoservicio/asistencias",
      name: "Asistencia",
      component: () =>
        import(
          /* webpackChunkName: "empleado" */ "src/Vistas/Asistencias/EmpleadoListado.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/autoservicio/incidencias",
      name: "Permisos o Justificaciones",
      component: () =>
        import(
          /* webpackChunkName: "empleado" */ "src/Vistas/Asistencias/EmpleadoIncidenciasSolicitud.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/autoservicio/vacaciones",
      name: "Vacaciones",
      component: () =>
        import(
          /* webpackChunkName: "empleado" */ "src/Vistas/Asistencias/EmpleadoVacacionesSolicitud.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/autoservicio/comentarios",
      name: "Comentarios",
      component: () =>
        import(
          /* webpackChunkName: "empleado" */ "src/Vistas/Main/Comentarios.vue"
        ),
      meta: { requiresAuth: true },
    },
  ],
};

import VistasAuthLayout from "src/Vistas/LayOuts/AuthLayout.vue";
// GeneralViews
import PaginaNoEncontrada from "src/Vistas/Main/PaginaNoEncontrada.vue";

let authVistas = {
  path: "/",
  component: VistasAuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/oem",
      name: "Inicio oem",
      component: () =>
        import(
          /* webpackChunkName: "usuarios" */ "src/Vistas/Usuarios/LogInOem.vue"
        ),
    },
    {
      path: "/login",
      name: "Inicio de sesión",
      component: () =>
        import(
          /* webpackChunkName: "usuarios" */ "src/Vistas/Usuarios/InicioSesion.vue"
        ),
    },
    {
      path: "/id_token*",
      name: "id_token",
      component: () =>
        import(
          /* webpackChunkName: "usuarios" */ "src/Vistas/Usuarios/State.vue"
        ),
    },
    {
      path: "/error*",
      name: "error",
      component: () =>
        import(
          /* webpackChunkName: "usuarios" */ "src/Vistas/Usuarios/Presenta.vue"
        ),
    },
    {
      path: "/state*",
      name: "state",
      component: () =>
        import(
          /* webpackChunkName: "usuarios" */ "src/Vistas/Usuarios/State.vue"
        ),
    },
    {
      path: "/presenta",
      name: "Presenta",
      component: () =>
        import(
          /* webpackChunkName: "usuarios" */ "src/Vistas/Usuarios/Presenta.vue"
        ),
    },
    {
      path: "/logout",
      name: "Cerrar sesión",
      component: () =>
        import(
          /* webpackChunkName: "usuarios" */ "src/Vistas/Usuarios/LogOut.vue"
        ),
    },
    {
      path: "/registro",
      name: "Registrarse",
      component: () =>
        import(
          /* webpackChunkName: "usuarios" */ "src/Vistas/Usuarios/Registro.vue"
        ),
    },
    {
      path: "/recuperar",
      name: "Recuperar Contraseña",
      component: () =>
        import(
          /* webpackChunkName: "usuarios" */ "src/Vistas/Usuarios/RecuperarPassword.vue"
        ),
    },
    {
      path: "/r/:linkID",
      name: "Referencia",
      component: () =>
        import(
          /* webpackChunkName: "referencia" */ "src/Vistas/Referencias/Consulta.vue"
        ),
    },
    {
      path: "/usuarios/nuevasuscripcion",
      name: "Nueva Suscripción",
      component: () =>
        import(
          /* webpackChunkName: "suscripcion" */ "src/Vistas/Usuarios/NuevaSuscripcion.vue"
        ),
    },
    {
      path: "/dispositivos/qr-nuevo-checador",
      name: "Nuevo Dispositivo",
      component: () =>
        import(
          /* webpackChunkName: "qr-nuevo-checador" */ "src/Vistas/Dispositivos/QR.NuevoChecador.vue"
        ),
    },
    {
      path: "/usuarios/invitacion-empleado",
      name: "Invitación",
      component: () =>
        import(
          /* webpackChunkName: "suscripcion" */ "src/Vistas/Usuarios/InvitacionEmpleado.vue"
        ),
    },
    {
      path: "/am/nuevo-dispositivo",
      name: "Nuevo Dispostivo",
      component: () =>
        import(
          /* webpackChunkName: "am" */ "src/Vistas/AM/NuevoDispositivo.vue"
        ),
    },
    {
      path: "/accesos/checador",
      name: "Checador",
      component: () =>
        import(
          /* webpackChunkName: "accesos" */ "src/Vistas/Accesos/NuevoGeneral.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/rondines/empleado",
      name: "Mis Rondines",
      component: () =>
        import(
          /* webpackChunkName: "rondines" */ "src/Vistas/Rondines/Empleado.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/nom035stps2018/:nom035stps2018ID/evaluaciones/:colaboradorID",
      name: "Evaluación Nom-035-STPS-2018",
      component: () =>
        import(
          /* webpackChunkName: "nom035stps2018" */ "src/Vistas/Nom035Stps2018/Evaluacion.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/nom035stps2018/evaluaciones/:nom035Stps2018EvaluacionID",
      name: "Nom-035-STPS-2018",
      component: () =>
        import(
          /* webpackChunkName: "nom035stps2018" */ "src/Vistas/Nom035Stps2018/Evaluacion.vue"
        ),
      meta: { requiresAuth: true },
    },

    {
      path: "/nom035stps2018/invitacion-empleado/:nom035Stps2018EvaluacionID",
      name: "Nom-035-STPS-2018 Auth",
      component: () =>
        import(
          /* webpackChunkName: "nom035stps2018" */ "src/Vistas/Nom035Stps2018/InvitacionEmpleado.vue"
        ),
    },
    {
      path: "/dispositivos/ligar/:id",
      name: "Ligar App",
      component: () =>
        import(
          /* webpackChunkName: "dispositivos" */ "src/Vistas/Dispositivos/LigarAppAplicar.vue"
        ),
    },
    {
      path: "/dispositivos/home",
      name: "Home dispositivo",
      component: () =>
        import(
          /* webpackChunkName: "dispositivos" */ "src/Vistas/Dispositivos/Home.vue"
        ),
      meta: { requiresAuth: true },
    },
  ],
};

import VistasVacioLayout from "src/Vistas/LayOuts/VacioLayout.vue";

let vacioVistas = {
  path: "/",
  component: VistasVacioLayout,
  name: "Vacio",
  children: [
    {
      path: "/nom035stps2018/evaluaciones/:nom035Stps2018EvaluacionID/:opcion",
      name: "Nom-035-STPS-2018 Consulta",
      component: () =>
        import(
          /* webpackChunkName: "nom035stps2018" */ "src/Vistas/Nom035Stps2018/Evaluacion.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/oem/empleado/vacaciones",
      name: "E. Vacaciones",
      component: () =>
        import(
          /* webpackChunkName: "empleado" */ "src/Vistas/Asistencias/EmpleadoVacacionesSolicitud.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/oem/empleado/asistencias",
      name: "E. Asistencia",
      component: () =>
        import(
          /* webpackChunkName: "empleado" */ "src/Vistas/Asistencias/EmpleadoListado.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/oem/empleado/incidencias",
      name: "E. Permisos o Justificaciones",
      component: () =>
        import(
          /* webpackChunkName: "empleado" */ "src/Vistas/Asistencias/EmpleadoIncidenciasSolicitud.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/oem/empleado/usuario",
      name: "E. Yo",
      component: () =>
        import(
          /* webpackChunkName: "empleado" */ "src/Vistas/Usuarios/PerfilPropio.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/oem/empleado/comentarios",
      name: "E. Comentarios",
      component: () =>
        import(
          /* webpackChunkName: "empleado" */ "src/Vistas/Main/Comentarios.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/oem/asistencias",
      name: "E. Asistencias",
      component: () =>
        import(
          /* webpackChunkName: "asistencias" */ "src/Vistas/Asistencias/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/rondines-home",
      name: "Rondines Home",
      component: () =>
        import(
          /* webpackChunkName: "rondines" */ "src/Vistas/Rondines/Home.vue"
        ),
      //meta: { requiresAuth: true }
    },

    {
      path: "/rondines-rondin",
      name: "Rondin",
      component: () =>
        import(
          /* webpackChunkName: "rondines" */ "src/Vistas/Rondines/Colaborador/Rondin.vue"
        ),
      //meta: { requiresAuth: true }
    },
    {
      path: "/rondines-contactos",
      name: "Contactos",
      component: () =>
        import(
          /* webpackChunkName: "rondines" */ "src/Vistas/Rondines/Colaborador/Contactos.vue"
        ),
      //meta: { requiresAuth: true }
    },
    {
      path: "/rondines-tareas",
      name: "Contactos",
      component: () =>
        import(
          /* webpackChunkName: "rondines" */ "src/Vistas/Rondines/Colaborador/Tareas.vue"
        ),
      //meta: { requiresAuth: true }
    },
    {
      path: "/rondines-alerta",
      name: "Contactos",
      component: () =>
        import(
          /* webpackChunkName: "rondines" */ "src/Vistas/Rondines/Colaborador/Alerta.vue"
        ),
      //meta: { requiresAuth: true }
    },
  ],
};
import VistasLimpioLayout from "src/Vistas/LayOuts/LimpioLayout.vue";
let limpioVistas = {
  path: "/",
  component: VistasLimpioLayout,
  name: "Vacio",
  children: [
    ///asistencias
  ],
};

let mainPages = {
  path: "/main",
  component: HomeLayout,
  name: "MainHome",
  children: [
    {
      path: "home",
      name: "Home",
      component: () =>
        import(/* webpackChunkName: "main" */ "src/Vistas/Main/Home.vue"),
      meta: { requiresAuth: true },
    },
  ],
};

let suscripcionesSinMenuPages = {
  path: "/suscripciones",
  component: VistasAuthLayout,
  name: "Suscripciones SMP",
  children: [
    {
      path: "pregunta",
      name: "Sin Suscripción",
      component: () =>
        import(
          /* webpackChunkName: "suscripciones" */ "src/Vistas/Suscripciones/Pregunta.vue"
        ),
      meta: { requiresAuth: true },
    },
  ],
};
let suscripcionesPages = {
  path: "/suscripciones",
  component: HomeLayout,
  name: "Suscripciones P",
  children: [],
};
let personalPages = {
  path: "/personal",
  component: HomeLayout,
  name: "PersonalHome",
  children: [
    {
      path: "/organigrama",
      name: "Organigrama",
      component: () =>
        import(
          /* webpackChunkName: "personal" */ "src/Vistas/Personal/Organigrama.vue"
        ),
      meta: {
        requiresAuth: true,
        hideContent: true,
        hideFooter: true,
        hideHomeNavBar: true,
      },
    },
    {
      path: "/personal",
      name: "Personal",
      component: () =>
        import(
          /* webpackChunkName: "personal" */ "src/Vistas/Personal/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/auto-registro",
      name: "Autoregistro",
      component: () =>
        import(
          /* webpackChunkName: "personal" */ "src/Vistas/AutoRegistro/index.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/auto-registro/:idAR/comparacion/:idCo",
      name: "Autoregistro",
      component: () =>
        import(
          /* webpackChunkName: "personal" */ "src/Vistas/AutoRegistro/Comparacion/index.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/personal/:id",
      name: "Perfil",
      component: () =>
        import(
          /* webpackChunkName: "personal" */ "src/Vistas/Personal/Perfil.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/grupos/",
      name: "Grupos",
      component: () =>
        import(
          /* webpackChunkName: "grupos" */ "src/Vistas/Grupos/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/centroscostos/",
      name: "Centros de costos",
      component: () =>
        import(
          /* webpackChunkName: "centroscostos" */ "src/Vistas/CentrosCostos/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/centroscostos/:id",
      name: "Centro de costo",
      component: () =>
        import(
          /* webpackChunkName: "centroscostos" */ "src/Vistas/CentrosCostos/Edicion.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/empresas/",
      name: "Empresas",
      component: () =>
        import(
          /* webpackChunkName: "empresas" */ "src/Vistas/Empresas/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/empresas/:id",
      name: "Empresa",
      component: () =>
        import(
          /* webpackChunkName: "empresas" */ "src/Vistas/Empresas/Edicion.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/puestos/",
      name: "Puestos",
      component: () =>
        import(
          /* webpackChunkName: "puestos" */ "src/Vistas/Puestos/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/puestos/:id",
      name: "Puesto",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "puestos" */ "src/Vistas/Puestos/Edicion.vue"
        ),
      meta: { requiresAuth: true },
    },

    {
      path: "/departamentos/",
      name: "Departamentos",
      component: () =>
        import(
          /* webpackChunkName: "departamentos" */ "src/Vistas/Departamentos/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/departamentos/:id",
      name: "Departamento",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "departamentos" */ "src/Vistas/Departamentos/Edicion.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/divisiones/",
      name: "Divisiones",
      component: () =>
        import(
          /* webpackChunkName: "divisiones" */ "src/Vistas/Divisiones/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/divisiones/:id",
      name: "División",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "divisiones" */ "src/Vistas/Divisiones/Edicion.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/gestiones/",
      name: "Gestiones",
      component: () =>
        import(
          /* webpackChunkName: "gestiones" */ "src/Vistas/Gestiones/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/gestiones/:id",
      name: "Gestión",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "gestiones" */ "src/Vistas/Gestiones/Edicion.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/nom035stps2018/",
      name: "NOM-035-STPS-2018",
      component: () =>
        import(
          /* webpackChunkName: "nom035stps2018" */ "src/Vistas/Nom035Stps2018/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/nom035stps2018/:id",
      name: "Evaluación NOM-035-STPS-2018",
      component: () =>
        import(
          /* webpackChunkName: "nom035stps2018" */ "src/Vistas/Nom035Stps2018/Edicion.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/nom035stps2018/:id/Dashboard",
      name: "Dashboard",
      component: () =>
        import(
          /* webpackChunkName: "nom035stps2018" */ "src/Vistas/Nom035Stps2018/Dashboard.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/nom035stps2018/:id/evaluaciones",
      name: "Evaluaciones NOM-035",
      component: () =>
        import(
          /* webpackChunkName: "nom035stps2018" */ "src/Vistas/Nom035Stps2018/Evaluaciones.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/sueldos/",
      name: "Sueldos",
      component: () =>
        import(
          /* webpackChunkName: "sueldos" */ "src/Vistas/Sueldos/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/sueldos/:id",
      name: "Sueldo",
      component: () =>
        import(
          /* webpackChunkName: "sueldos" */ "src/Vistas/Sueldos/Colaborador.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/revision-mejora/",
      name: "Revision-Mejora",
      component: () =>
        import(
          /* webpackChunkName: "revision-mejora" */ "src/Vistas/RevisionMejora/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/revision-mejora/nuevo",
      name: "Nuevo RM",
      component: () =>
        import(
          /* webpackChunkName: "revision-mejora" */ "src/Vistas/RevisionMejora/Edicion.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/revision-mejora/:id",
      name: "Edición RM",
      component: () =>
        import(
          /* webpackChunkName: "revision-mejora" */ "src/Vistas/RevisionMejora/Edicion.vue"
        ),
      meta: { requiresAuth: true },
    },
    /*   {
      path: '/revision-mejora/:id/Dashboard',
      name: 'Dashboard',
      component: () =>
        import(/* webpackChunkName: "revision-mejora" */ /*'src/Vistas/RevisionMejora/Dashboard.vue'),
      meta: { requiresAuth: true }
    },*/
    {
      path: "/revision-mejora/:id/evaluaciones",
      name: "Evaluaciones ",
      component: () =>
        import(
          /* webpackChunkName: "revision-mejora" */ "src/Vistas/RevisionMejora/Evaluaciones.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/revision-mejora/evaluaciones/:evaluacionID",
      name: "Evaluación",
      component: () =>
        import(
          /* webpackChunkName: "revision-mejora" */ "src/Vistas/RevisionMejora/Evaluacion.vue"
        ),
      meta: { requiresAuth: true },
    },
  ],
};
let accesosPages = {
  path: "/Accesos",
  component: HomeLayout,
  name: "AccesosHome",
  children: [
    {
      path: "/accesos",
      name: "Accesos",
      component: () =>
        import(
          /* webpackChunkName: "accesos" */ "src/Vistas/Accesos/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/accesos/nuevo",
      name: "Nueva Checada",
      component: () =>
        import(
          /* webpackChunkName: "accesos" */ "src/Vistas/Accesos/NuevoGeneral.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/accesos/modo-foto",
      name: "Modo Foto",
      component: () =>
        import(
          /* webpackChunkName: "accesos" */ "src/Vistas/Accesos/ModoFoto.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/accesos/comedor",
      name: "Comedor",
      component: () =>
        import(
          /* webpackChunkName: "accesos" */ "src/Vistas/Accesos/Comedor.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/accesos/mapa",
      name: "Mapa",
      component: () =>
        import(/* webpackChunkName: "accesos" */ "src/Vistas/Accesos/Mapa.vue"),
      meta: {
        hideContent: true,
        hideFooter: true,
        requiresAuth: true,
      },
    },
  ],
};
let amPages = {
  path: "/am",
  component: HomeLayout,
  name: "amHome",
  children: [
    {
      path: "/am",
      name: "AM",
      component: () =>
        import(/* webpackChunkName: "am" */ "src/Vistas/AM/Listado.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/auditorias",
      name: "Auditorias",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "auditorias" */ "src/Vistas/Auditorias/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },
  ],
};

let asistenciasPages = {
  path: "/asistencias",
  component: HomeLayout,
  name: "AsistenciasHome",
  children: [
    {
      path: "/asistencias",
      name: "Asistencias",
      component: () =>
        import(
          /* webpackChunkName: "asistencias" */ "src/Vistas/Asistencias/Listado.vue"
        ),
      meta: {
        requiresAuth: true,
        //  hideFooter: true
      },
    },
    {
      path: "/asistencias/metricas",
      name: "Métricas de Asistencia",
      component: () =>
        import(
          /* webpackChunkName: "asistencias" */ "src/Vistas/Asistencias/Metricas.vue"
        ),
      meta: {
        requiresAuth: true,
        //  hideFooter: true
      },
    },
    {
      path: "/turnos",
      name: "Turnos",
      component: () =>
        import(
          /* webpackChunkName: "turnos" */ "src/Vistas/Turnos/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/turnos/nuevo/:tipoTurno",
      name: "Turno Nuevo",
      component: () =>
        import(/* webpackChunkName: "turnos" */ "src/Vistas/Turnos/Nuevo.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/turnos/:id",
      name: "Edición de Turno",
      component: () =>
        import(
          /* webpackChunkName: "turnos" */ "src/Vistas/Turnos/Edicion.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/diasfestivos",
      name: "Dias Festivos",
      component: () =>
        import(
          /* webpackChunkName: "diasfestivos" */ "src/Vistas/DiasFestivos/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/tiposincidencias",
      name: "Tipos de Incidencias",
      component: () =>
        import(
          /* webpackChunkName: "tiposincidencias" */ "src/Vistas/TiposIncidencias/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/tiposincidencias/:id",
      name: "Tipo de Incidencia",
      component: () =>
        import(
          /* webpackChunkName: "tiposincidencias" */ "src/Vistas/TiposIncidencias/Edicion.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/vacaciones/saldos",
      name: "Saldos de Vacaciones",
      component: () =>
        import(
          /* webpackChunkName: "reglasvacaciones" */ "src/Vistas/Vacaciones/Saldos.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/reglasVacaciones",
      name: "Reglas de Vacaciones",
      component: () =>
        import(
          /* webpackChunkName: "reglasvacaciones" */ "src/Vistas/ReglasVacaciones/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/reglasVacaciones/nuevo/",
      name: "Nueva regla de Vacaciones",
      component: () =>
        import(
          /* webpackChunkName: "turnos" */ "src/Vistas/ReglasVacaciones/Nuevo.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/reglasVacaciones/:id",
      name: "Edición de regla de Vacaciones",
      component: () =>
        import(
          /* webpackChunkName: "turnos" */ "src/Vistas/ReglasVacaciones/Edicion.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/prenominas",
      name: "Prenominas",
      component: () =>
        import(
          /* webpackChunkName: "prenominas" */ "src/Vistas/Prenominas/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },

    {
      path: "/prenominas/:id",
      name: "Prenomina",
      component: () =>
        import(
          /* webpackChunkName: "prenominas" */ "src/Vistas/Prenominas/Edicion.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/solicitudes/",
      name: "Solicitudes",
      component: () =>
        import(
          /* webpackChunkName: "solicitudes" */ "src/Vistas/Solicitudes/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/cargos/",
      name: "Cargos",
      component: () =>
        import(
          /* webpackChunkName: "cargos" */ "src/Vistas/Cargos/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },
  ],
};

let ubicacionesPages = {
  path: "/",
  component: HomeLayout,
  name: "UbicacionesHome",
  children: [
    {
      path: "/ubicaciones",
      name: "Ubicaciones",
      component: () =>
        import(
          /* webpackChunkName: "ubicaciones" */ "src/Vistas/Ubicaciones/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/ubicaciones/:id",
      name: "Ubicacion",
      component: () =>
        import(
          /* webpackChunkName: "ubicaciones" */ "src/Vistas/Ubicaciones/Edicion.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/regiones",
      name: "Regiones",
      component: () =>
        import(
          /* webpackChunkName: "regiones" */ "src/Vistas/Regiones/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },

    {
      path: "/rondines",
      name: "Rondines",
      component: () =>
        import(
          /* webpackChunkName: "rondines" */ "src/Vistas/Rondines/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },

    {
      path: "/rondines/:id",
      name: "Rondin",
      component: () =>
        import(
          /* webpackChunkName: "rondines" */ "src/Vistas/Rondines/Edicion.vue"
        ),
      meta: { requiresAuth: true },
    },

    {
      path: "/rondines/:id/actividades",
      //      path: '/rondines/actividades/:id',
      name: "Rondin Actividades",
      component: () =>
        import(
          /* webpackChunkName: "accesos" */ "src/Vistas/Rondines/Actividades.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/rondines/Dashboard",
      //      path: '/rondines/actividades/:id',
      name: "Dashboard rondines",
      component: () =>
        import(
          /* webpackChunkName: "accesos" */ "src/Vistas/Rondines/Dashboard.vue"
        ),
      meta: { requiresAuth: true },
    },
  ],
};

let dispositivosPages = {
  path: "/",
  component: HomeLayout,
  name: "DispositivosHome",
  children: [
    {
      path: "/dispositivos",
      name: "Dispositivos",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dispositivos" */ "src/Vistas/Dispositivos/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },

    {
      path: "/dispositivos/:id",
      name: "Dispositivo",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dispositivos" */ "src/Vistas/Dispositivos/Edicion.vue"
        ),
      meta: { requiresAuth: true },
    },
  ],
};
/*
let embajadoresPages = {
  path: '/Embajadores',
  component: HomeLayout,
  name: 'EmbajadoresHome',
  children: [
    {
      path: '/embajadores',
      name: 'Embajadores',
      component: () =>
        import(*/ /* webpackChunkName: "embajadores" */ /*'src/Vistas/Referencias/Listado.vue'),
      meta: { requiresAuth: true }
    },

  ]
}
*/
let impresionVistas = {
  path: "/impresion",
  component: VistasAuthLayout,
  name: "Impresion",
  children: [
    {
      path: "/embajadores/suscripcion/:linkID",
      name: "Embajadores - Suscripcion",
      component: () =>
        import(
          /* webpackChunkName: "embajadores" */ "src/Vistas/Referencias/Suscripcion.impresion.vue"
        ),
      meta: { requiresAuth: true },
    },
  ],
};

let usuariosPages = {
  path: "/",
  component: HomeLayout,
  name: "Usuarios",
  children: [
    {
      path: "/usuarios/perfil",
      name: "Mi Perfil",
      component: () =>
        import(
          /* webpackChunkName: "usuarios" */ "src/Vistas/Usuarios/PerfilPropio.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/usuarios/sesiones",
      name: "Sesiones",
      component: () =>
        import(
          /* webpackChunkName: "usuarios" */ "src/Vistas/Usuarios/Sesiones.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/usuarios/tomafoto",
      name: "Toma de Foto",
      component: () =>
        import(
          /* webpackChunkName: "usuarios" */ "src/Vistas/Usuarios/TomaFoto.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/usuarios",
      name: "Listado de usuarios",
      component: () =>
        import(
          /* webpackChunkName: "usuarios" */ "src/Vistas/Usuarios/Listado.vue"
        ),
      meta: { requiresAuth: true },
    },
    {
      path: "/usuarios/comentarios",
      name: "Tu Opinion",
      component: () =>
        import(
          /* webpackChunkName: "empleado" */ "src/Vistas/Main/Comentarios.vue"
        ),
      meta: { requiresAuth: true },
    },
  ],
};
const routes = [
  {
    path: "/",
    name: "Default",
    component: () =>
      import(/* webpackChunkName: "default" */ "src/Vistas/Main/Default.vue"),
  },
  authVistas,
  empleadoPages,
  autoservicioPages,
  mainPages,
  suscripcionesSinMenuPages,
  suscripcionesPages,
  usuariosPages,
  dispositivosPages,
  personalPages,
  limpioVistas,
  accesosPages,
  asistenciasPages,
  ubicacionesPages,
  // embajadoresPages,
  amPages,
  vacioVistas,
  impresionVistas,
  // Agregar código para mostrar vacio
  { path: "*", name: "Página no encontrada", component: PaginaNoEncontrada },
];

export default routes;
